import React from "react"
import { Title, Meta } from "react-head"
import MainLayout from "../components/MainLayout"
import StudentSuccessStories from "../components/student-success-stories/StudentSuccessStories"

const SuccessStories = () => {
  return (
    <MainLayout>
      <Title>
        Codetrain Africa | Success Stories - Ghana | Apps coding bootcamp
      </Title>
      <Meta
        name="description"
        content="Codetrain empowers people to rise digitally by training them
        to become software developers and global leaders; setting them
        up to make huge impacts in their communities. Micah flew in from Gambia to persue 
        his dream of becoming a professional software developer. After completing Codetrain's 
        program, he has now built several apps and consulted on client projects."
      />
      <StudentSuccessStories />
    </MainLayout>
  )
}

export default SuccessStories
