import hannah from "../../assets/img/people/hannah.jpg"
import koduah from "../../assets/img/people/koduah.jpg"
import justice from "../../assets/img/people/justice.png"
import tony from "../../assets/img/people/tony.jpg"
import titus from "../../assets/img/people/titus.jpg"
import emmanuel from "../../assets/img/people/emmanuel.jpg"
import shubham from "../../assets/img/people/shubham.jpg"
import zadok from "../../assets/img/people/zadok.jpg"
import comfort from "../../assets/img/people/comfort.jpg"
import collins from "../../assets/img/people/collins.jpg"
import kwesi from "../../assets/img/people/kwesi.jpg"
import ayisi from "../../assets/img/people/ayisi.jpg"
import nyhiraba from "../../assets/img/people/nyiraba.jpg"
import micah from "../../assets/img/people/micah.jpg"
import gerrard from "../../assets/img/people/gerrard.jpg"
import gabriel from "../../assets/img/people/gabriel.jpg"
import anthony from "../../assets/img/people/anthony.jpg"
import sanda from "../../assets/img/people/sanda.jpg"
import moses from "../../assets/img/people/moses.jpg"
import latif from "../../assets/img/people/latif.jpg"
import razak from "../../assets/img/people/razak.jpg"
import makafui from "../../assets/img/people/makafui.jpg"
import ruth from "../../assets/img/people/ruth-min (copy).jpg"
import elton from "../../assets/img/people/eltin.jpg"
import kofi from "../../assets/img/people/kofi.jpg"
import habib from "../../assets/img/people/habib(1).jpg"
import thomas from "../../assets/img/people/thomas (copy).jpg"
import nana from "../../assets/img/people/nana.jpg"
import awal from "../../assets/img/people/awal(1).jpg"
import emerald from "../../assets/img/people/emerald.jpg"
import elikem from "../../assets/img/people/eli.jpg"
import elias from "../../assets/img/people/elias.jpg"
import bless from "../../assets/img/people/bless.jpg"
import afia from "../../assets/img/people/afia.jpg"
import paul from "../../assets/img/people/paul.jpg"
import allen from "../../assets/img/people/allen (copy).jpg"
import derrick from "../../assets/img/people/derrick.jpg"
import rachel from "../../assets/img/people/rachel.jpg"
import zak from "../../assets/img/people/zak.jpg"
import doris from "../../assets/img/people/doris.jpg"
import joel from "../../assets/img/people/joel.jpg"
import bola from "../../assets/img/people/bola.jpg"
import precious from "../../assets/img/people/precious.jpeg"
// import ibrahim from "../../assets/img/people/ibrahim.jpeg"
import ivor from "../../assets/img/people/ivor.jpg"
import joseph from "../../assets/img/people/joseph.png"
import ofoe from "../../assets/img/people/ofoe.jpeg"
import solomon from "../../assets/img/people/solomon.jpeg"
import anita from "../../assets/img/people/anita.jpeg"
import gerrard_a from "../../assets/img/people/gerrard_2.jpeg"
import kezia from "../../assets/img/people/kezia.jpeg"
import adams from "../../assets/img/people/adams.jpg"
import clifford from "../../assets/img/people/clifford.jpg"
import doreen from "../../assets/img/people/doreen.jpeg"
import samuel from "../../assets/img/people/samuel.jpg"
import kingsfield from "../../assets/img/people/kingsfield.jpg"
import alberto from "../../assets/img/people/alberto.jpeg"
import boureima from "../../assets/img/people/boureima.jpeg"
import daniel from "../../assets/img/people/daniel.jpeg"
import elikem2 from "../../assets/img/people/elikem.jpeg"
import irene from "../../assets/img/people/irene.jpeg"
import jermaine from "../../assets/img/people/jermaine.jpeg"
import lovelace from "../../assets/img/people/lovelace.jpeg"
import mimi from "../../assets/img/people/mimi.jpeg"
import oliver from "../../assets/img/people/oliver.jpeg"
import princess from "../../assets/img/people/princess.jpeg"
import kingsleyb from "../../assets/img/people/kingsleyb.jpeg"
import kofo from "../../assets/img/people/kofo.jpg"

export const studentsData = [
  // {
  //   name_pos: "Ibrahim, Software Engineer",
  //   story: `
  //   My dream was to become a Software Engineer at a top
  //   company and Codetrain gave me the proper foundation to
  //   pursue my dream. After the training, I was able to join
  //   Microsoft as a Software Engineer.
  //       `,
  //   image: ibrahim,
  // },
  {
    name_pos: "Anita, Frontend Developer",
    story: `
            Together with her team mate, they built an online COVID-19 pre-assessment test 
            with doctor consultation. After the training programme, she landed a Frontend 
            Developer role at Africa Software Solutions.
        `,
    image: anita,
  },
  {
    name_pos: "Ivor, Frontend Engineer",
    story: `
            Ivor got an offer to join Mpedigree as a Frontend Engineer after completing 
            the software development training at Codetrain.
        `,
    image: ivor,
  },
  {
    name_pos: "Precious, Frontend Developer",
    story: `
            After studying at Codetrain, Precious is now employed as a Frontend Web
            Developer at Aiivon, an innovation hub at Abuja.
        `,
    image: precious,
  },
  {
    name_pos: "Gerrard, Fullstack Developer",
    story: `
            Gerrard built several apps including an app to help frontline health 
            workers share learnings about COVID-19 in real time. He got an offer 
            to join Meqasa right after graduation.
        `,
    image: gerrard_a,
  },
  {
    name_pos: "Kezia, Mobile Developer",
    story: `
    Together with her team mate, they built an online COVID-19 pre-assessment test 
    with doctor consultation. After the training programme, she landed an internship 
    with an airline company in Ghana.
        `,
    image: kezia,
  },
  {
    name_pos: "Ofoe, Mobile Developer",
    story: `
            He built a mobile app with his team mate to educate the public about 
            the dangers of COVID-19 by enabling patients and people affected by the virus 
            share their stories.
        `,
    image: ofoe,
  },
  {
    name_pos: "Solomon, Fullstack Developer",
    story: `
            Solomon built several impressive apps during the training program that
            got him internships with a UK based company and also an Indian company.
        `,
    image: solomon,
  },
  {
    name_pos: "Joseph, Fullstack Developer",
    story: `
            Joseph built an app to help the general public easily find and purchase PPEs 
            during the COVID-19 pandemic after going through our training. He had no prior 
            experience in programming before Codetrain.
        `,
    image: joseph,
  },
  {
    name_pos: "Hannah, Web Developer",
    story: `
            Hannah excelled quickly at Codetrain to become one of the best Frontend 
            Developers. She built several projects including an app for Nanny agencies.
        `,
    image: hannah,
  },
  {
    name_pos: "Koduah, Mobile Developer",
    story: `
            He followed his passion and after his training at Codetrain, he was able to
            fulfill his childhood dream of building his own mobile games.
        `,
    image: koduah,
  },
  {
    name_pos: "Justice, Mobile & Web Developer",
    story: `
            Justice was one of the most exciting students at Codetrain. With great determination,
            he was able to excel in both mobile and web development within a short period. Amazing!
        `,
    image: justice,
  },
  {
    name_pos: "Tony, Mobile Developer",
    story: `
            Tony dropped out of college to pursue his dream of becoming a software developer.
            Codetrain enabled him to achieve that. He now helps train the next generation of 
            developers.
        `,
    image: tony,
  },
  {
    name_pos: "Titus, Mobile Developer",
    story: `
            He didn't let distance hinder him. While in Liberia, he did Codetrain's virtual
            training which enabled him to build the first ever online market place for 
            his home country Liberia.
        `,
    image: titus,
  },
  {
    name_pos: "Emmanuel, Web Developer",
    story: `
            After studying at Codetrain, Emmanuel is now a professional web developer
            with a lot of projects experience under his belt. He currently works at 
            Standard Chartered Bank.
        `,
    image: emmanuel,
  },
  {
    name_pos: "Shubham, Mobile App Developer",
    story: `
            Shubham joined Codetrain after high school. Upon completing the training,
            he built several apps including LearnBox, an educational Android game for kids.
        `,
    image: shubham,
  },
  {
    name_pos: "Zadok, Web Developer",
    story: `
            Zadok has built many applications after completing the Codetrain practical training. 
            He recently worked on Kanvas, a web app for a startup in Germany.
        `,
    image: zadok,
  },
  {
    name_pos: "Comfort, Web Developer",
    story: `
            Although Comfort is still in College, she has gained a lot of projects experience through
            Codetrain's practical training. She built a real estate listing web application
            from scratch.
        `,
    image: comfort,
  },
  {
    name_pos: "Bola, Web Developer",
    story: `
            She came from a Law background and excelled at Codetrain to become 
            a web developer with lots of projects experience. She built Pantry, an
            app to help women get recipes based on the ingredients they have.
        `,
    image: bola,
  },
  {
    name_pos: "Collins, Web Developer",
    story: `
            Collins flew in from Liberia to persue his dream of becoming a professional software 
            developer. He has now acquired a lot of projects experience and even consulted on 
            real client projects.
        `,
    image: collins,
  },
  {
    name_pos: "Richard, Web Developer",
    story: `
            After completing the training, Richard has acquired a lot of projects experience. 
            He built a hotel listing web application from scratch which he showcased on the 
            Accra Apps Demo Day.
        `,
    image: kwesi,
  },
  {
    name_pos: "Ayisi, Mobile App Developer",
    story: `
            Ayisi is now a professional Mobile App Developer, with lots of projects experience 
            after successfully completing Codetrain's practical training. He is also the IT 
            Manager at SAS Finance Group.
        `,
    image: ayisi,
  },
  {
    name_pos: "Nana Adwoa Nyiraba, Web Developer",
    story: `
            Nyiraba started the training with no prior tech skills to become a professional 
            Frontend Developer. She recently worked on Kanvas, a web app for a startup in Germany.
        `,
    image: nyhiraba,
  },
  {
    name_pos: "Micah, Web Developer",
    story: `
            Micah flew in from Gambia to persue his dream of becoming a professional 
            software developer. After completing Codetrain's program, he has now built several 
            apps and consulted on client projects.
        `,
    image: micah,
  },
  {
    name_pos: "Gerrard, Mobile App Developer",
    story: `
            Gerrard is only in High School but has acquired a lot of projects experience.
            His latest Android app, Think Outside The Box, helps kids learn about sanitation and how
            to prevent diseases.
        `,
    image: gerrard,
  },
  {
    name_pos: "Gabriel, Mobile App Developer",
    story: `
            Gabriel joined Codetrain with no prior skills in software. He is now a professional
            Android developer and has a lot of experience in building mobile apps.
        `,
    image: gabriel,
  },
  {
    name_pos: "Anthony, Mobile App Developer",
    story: `
            After successfully completing Codetrain's mobile app training, Anthony is now an 
            experienced Android Developer. He recently built an app for vehicle auctioning.
        `,
    image: anthony,
  },
  {
    name_pos: "Sanda, Web Developer",
    story: `
            With absolutely no experience in IT, Sanda has worked hard to become a very
            competent Frontend developer with a lot of projects experience, after going 
            through the training program.
        `,
    image: sanda,
  },
  {
    name_pos: "Moses, Mobile App Developer",
    story: `
            Moses joined Codetrain as an artist to explore ways to merge technology with 
            his art skills. After the training, he built a mobile app to help artists all 
            around the world sell their artwork. 
        `,
    image: moses,
  },
  {
    name_pos: "Latif, Web Developer",
    story: `
            After successfully completing Codetrain's web development training, Latif joined 
            Bidibuild, a tech startup in Ghana to enhance his skills and gain more industry 
            experience.
        `,
    image: latif,
  },
  {
    name_pos: "Abdul-Razak, Web Developer",
    story: `
            Razak got fully employed as a web developer after completing Codetrain's practical 
            training program in coding.
        `,
    image: razak,
  },
  {
    name_pos: "Makafui, Android Developer",
    story: `
            19yr old Makafui landed his first job as a junior Android developer just after 
            completing Codetrain's Mobile App course.
        `,
    image: makafui,
  },
  {
    name_pos: "Ruth, Frontend Developer",
    story: `
            With dedication and hardwork, Ruth has excelled to become one of the finest 
            frontend developers and is now an intern at Codetrain.
        `,
    image: ruth,
  },
  {
    name_pos: "Elton-Chris, Web Developer",
    story: `
            After completing Codetrain's web development course, Elton got employed at Zoobashop, 
            one of Ghana's biggest online shop.
        `,
    image: elton,
  },
  {
    name_pos: "Kofi, Web Developer",
    story: `
            After learning at Codetrain, Kofi together with his friends, launched a marketing 
            software service called WhatsAd.
        `,
    image: kofi,
  },
  {
    name_pos: "Habib, Android Developer",
    story: `
            After going through Codetrain's Android training, he built several Android 
            apps including a QR Code Scanner App. He is now an intern at Codetrain.
        `,
    image: habib,
  },
  {
    name_pos: "Thomas, Web Developer",
    story: `
            Thomas excelled at Codetrain to become a competent web developer. After, he joined
            Meqasa, one of Ghana's biggest companies to build mobile apps.
        `,
    image: thomas,
  },
  {
    name_pos: "Josephine, Web Developer",
    story: `
            After learning at Codetrain, Josephine joined the team as Admissions & Student 
            Relations Fellow.
        `,
    image: nana,
  },
  {
    name_pos: "Mubarak, Mobile & Web Developer",
    story: `
            Mubarak is Codetrain's fastest learning student. He's now a mobile & web developer 
            who works at Codetrain to train upcoming developers.
        `,
    image: awal,
  },
  {
    name_pos: "Emerald, Web Developer",
    story: `
            She's one of the first ladies to join Codetrain. After completing the program, 
            she built several websites for NGOs.  
        `,
    image: emerald,
  },
  {
    name_pos: "Elikem, Mobile Developer",
    story: `
            After completing the Android course at Codetrain, Elikem landed a job as a 
            professional mobile developer at Ampersand, one of the leading Tech companies 
            in Ghana. 
        `,
    image: elikem,
  },
  {
    name_pos: "Elias, Web Developer",
    story: `
            Elias got a job at the World Trade Center, Ghana, as a web developer after 
            completing Codetrain's web developer course.
        `,
    image: elias,
  },
  {
    name_pos: "Bless, Web Developer",
    story: `
        With no prior experience in coding, Bless worked hard to become a professional web
        developer. He single handedly built a fully functional website for his college.
        `,
    image: bless,
  },
  {
    name_pos: "Afia, Web Developer",
    story: `
            Afia is the first lady to finish our virtual program. After, she started Kuumba 
            Media, a VOD streaming service for African video content.
        `,
    image: afia,
  },
  {
    name_pos: "Paul, Web Developer",
    story: `
            Even though Paul came from a Political Science background, he excelled at 
            Codetrain to secure a lucrative tech job at Talamus Ghana.
        `,
    image: paul,
  },
  {
    name_pos: "Allen, Android Developer",
    story: `
            At the age of 16, Allen built a couple of Android games after learning Android
            App development at Codetrain over the vacation.
        `,
    image: allen,
  },
  {
    name_pos: "Derrick, Web Developer",
    story: `
            He excelled at Codetrain to become a professional web developer which ultimately saw
            him win an award as 2017 Best ICT Teacher in the Eastern Region of Ghana.
        `,
    image: derrick,
  },
  {
    name_pos: "Rachel, Web Developer",
    story: `
            After studying web development at Codetrain, Rachel joined the Codetrain team as
            community manager.
        `,
    image: rachel,
  },
  {
    name_pos: "Zak, Web Developer",
    story: `
            Zak is Codetrain's first student and now a professional web developer who has 
            worked on local and international projects. He currently works at Codetrain 
            to train upcoming developers.
        `,
    image: zak,
  },
  {
    name_pos: "Doris, Web Developer",
    story: `
            Doris is the first lady to complete Codetrain's course. She is now an experienced web
            developer and helps Codetrain students with their projects.
        `,
    image: doris,
  },
  {
    name_pos: "Joel, Android Developer",
    story: `
            Joel learned how to build Android apps from scratch at Codetrain and he's now a mobile
            app developer who works at Forhey.
        `,
    image: joel,
  },
  {
    name_pos: "Adams, Fullstack Developer",
    story: `
            After going through Codetrain's training programme, Adams successfully started his career as a software developer.
        `,
    image: adams,
  },
  {
    name_pos: "Doreen, Frontend Developer",
    story: `Doreen joined Codetrain right after high school and got matched to a software company to start her career as a software developer.`,
    image: doreen,
  },
  {
    name_pos: "Clifford, Fullstack Developer",
    story: `Clifford's presentation was one of the best during the demo day, he got recommended by one of the judges to EmPharma where he successfully started his career as a software developer.`,
    image: clifford,
  },
  {
    name_pos: "Samuel, Fullstack Developer",
    story: `After Codetrain, Samuel started his career as a freelance software developer.`,
    image: samuel,
  },
  {
    name_pos: "Kingsfield, Fullstack Developer",
    story: `Kingsfield started his career as s software developer after completing Codetrain's training programme.`,
    image: kingsfield,
  },
  {
    name_pos: "Alberto, Fullstack Developer",
    story: `After completing Codetrain, Alberto started his career as a software developer in a Ghanaian startup based in Kenya.`,
    image: alberto,
  },
  {
    name_pos: "Boureima, Fullstack Developer",
    story: `Boureima returned to his native country, Mali after completinmg Codetrain to establish his own software consulting business.`,
    image: boureima,
  },
  {
    name_pos: "Daniel, Fullstack Developer",
    story: `Daniel was part a team that won a startup weekend for waste and recycling in Ghana, he has successfully started his software company to enable people to support exciting but small and needy businesses in Ghana.`,
    image: daniel,
  },
  {
    name_pos: "Elikem, Frontend Developer",
    story: `After Elikem's successful software development training at Codetrain, he joined a software company based in East Legon to start his career.`,
    image: elikem2,
  },
  {
    name_pos: "Irene, Digital Marketer",
    story: `While sudying at Codetrain, Irene discovered her dream and passion to help businesses grow and that drove her to start her career in digital marketing.`,
    image: irene,
  },
  {
    name_pos: "Jermaine, Fullstack Developer",
    story: `Jermaine is the youngest person to complete Codetrain's training programme at only 11 years, he successfully started his career with a software consulting company.`,
    image: jermaine,
  },
  {
    name_pos: "Lovelace, UI/UX Designer",
    story: `After completing Codetrain, Lovelace decided to pursue a career UI/UX design and product management.`,
    image: lovelace,
  },
  {
    name_pos: "Mimi, Fullstack Developer",
    story: `Mimi is one Ghana's upcoming leaders in technology, she has a background in media and she uses her spare time to inspire women to go into tech.`,
    image: mimi,
  },
  {
    name_pos: "Oliver, Fullstack Developer",
    story: `Oliver kickstarted his career in EmPharma and he's currently based in Germany, contributing to Berlin's tech ecosystem.`,
    image: oliver,
  },
  {
    name_pos: "Princess, Fullstack Developer",
    story: `After completing high school, Princess defied all odds to realize her dream of becoming a software developer.`,
    image: princess,
  },
  {
    name_pos: "Kingley, Fullstack Developer",
    story: `Kingley realized his dream of becoming a software enterpreneur when he joined MEST after going through Codetrain's programme to become a software developer.`,
    image: kingsleyb,
  },
  {
    name_pos: "Mary, Fullstack Developer",
    story: `After graduating Codetrain, Mary successfully handled the Codetrain kids training programme. She's now a teaching fellow at Codetrain.`,
    image: kofo,
  },
]
