import React from "react";
import { studentsData } from "./student-success-stories-data";
import "./studentSuccessStories.scss";

const StudentSuccessStories = () => {
  const studentInfo = studentsData.map(student => {
    return (
      <div className="col col-md-6 col-lg-3 mb-4">
        <div className="card h-100">
          <img
            src={student.image}
            className="card-img-top"
            alt={student.name_pos}
          />
          <div className="card-body">
            <h5 className="card-title">{student.name_pos}</h5>
            <p className="card-text">{student.story}</p>
          </div>
        </div>
      </div>
    )
  });
  return (
    <div className="section-container student-success-stories-section">
      <div className="container">
        <div className="main-section-heading">
          <h3 className="text-center">Codetrain Success Stories</h3>
        </div>
        <div className="row student-success-stories-section__content">
          <div className="card-deck">
            <div className="row row-cols-1 row-cols-md-4">
              { studentInfo }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudentSuccessStories;
